import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
  Modal,
} from "react-bootstrap";
import deleteButton from "../../assets/delete.png";
import searchPic from "../../assets/search.svg";
import addImg from "../../assets/plus.svg";
import editButton from "../../assets/edit.png";
import viewButton from "../../assets/view.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchOrderList from "../../redux/thunk/fetchOrderList";
import fetchProductList from "../../redux/thunk/fetchProductList";
import fetchOrderAvailableDateList from "../../redux/thunk/fetchOrderAvailableDate";
import fetchCreateOrder from "../../redux/thunk/fetchOrderCreate";
import fetchDeleteOrder from "../../redux/thunk/fetchOrderDelete";
import fetchUpdateOrder from "../../redux/thunk/fetchUpdateOrder";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
export default function Order() {
  const [createSector, setCreateSector] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchNumber, setSearchNumber] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [altPhoneNumber, setAltPhoneNumber] = useState("");
  // const [product, setProduct] = useState("");
  // const [quantity, setQuantity] = useState(0);
  const [shippingDate, setShippingDate] = useState("");
  const [inputs, setInputs] = useState([
    { product_id: null, quantity: 0, note: null },
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteOrderNumber, setDeleteOrderNumber] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const orderlist = useSelector((state) => state.orderlist);
  const orderCreate = useSelector((state) => state.orderCreate);
  const orderUpdate = useSelector((state) => state.orderUpdate);
  const productList = useSelector((state) => state.productList);
  const orderAvailableDate = useSelector((state) => state.orderAvailableDate);
  const [noteVisible, setNoteVisible] = useState(
    inputs.map(() => false) // Initialize all notes as hidden
  );
  const { isError, loading, orders, error } = orderlist || {};
  const {
    isError: orderIsError,
    loading: orderLoading,
    error: orderError,
    success,
  } = orderCreate || {};
  const {
    isError: updateIsError,
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = orderUpdate || {};
  const { dates } = orderAvailableDate || [];
  const {
    isError: productListIsError,
    error: productListError,
    loading: productListLoading,
    products,
  } = productList || {};
  const { userInfo } = login || {};
  const reset = useCallback(() => {
    setOrderNumber("");
    setCustomerName("");
    setShippingAddress("");
    setCity("");
    setState("");
    setZipCode("");
    setPhoneNumber("");
    setAltPhoneNumber("");
    setShippingDate("");

    setInputs([{ product_id: null, quantity: 0, note: null }]);
    setNoteVisible([false]);
  }, []);
  const createPage = (e) => {
    e.preventDefault();
    reset();
    setCreateSector(true);
    setEditMode(false);
  };
  const OrderDetails = (id) => {
    navigate(`/order/${id}`);
  };

  const createOrder = (e) => {
    e.preventDefault();
    let order = {
      order_number: orderNumber,
      customer_name: customerName,
      shipping_address: shippingAddress,
      city: city,
      state: state,
      zip_code: zipCode,
      phone_number: phoneNumber,
      alt_phone_number: altPhoneNumber,
      order_list: inputs,
      shipment_id: shippingDate,
    };
    dispatch(fetchCreateOrder(order));
    setShouldReload(true);
  };
  const updateOrder = (e) => {
    e.preventDefault();
    console.log(shippingDate);
    let order = {
      order_number: orderNumber,
      customer_name: customerName,
      shipping_address: shippingAddress,
      city: city,
      state: state,
      zip_code: zipCode,
      phone_number: phoneNumber,
      alt_phone_number: altPhoneNumber,
      order_list: inputs,
      shipment_id: shippingDate,
    };
    dispatch(fetchUpdateOrder(order));
    setShouldReload(true);
  };
  const searchButton = (e) => {
    e.preventDefault();
    dispatch(fetchOrderList(searchNumber));
  };

  const editPage = (
    order_list, // Added this parameter to handle multiple items
    order_number,
    customer_name,
    shipping_address,
    city,
    state,
    zip_code,
    phone_number,
    alt_phone_number,
    available_ship_date
  ) => {
    setCreateSector(false);
    setEditMode(true);
    setOrderNumber(order_number);
    setCustomerName(customer_name);
    setShippingAddress(shipping_address);
    setCity(city);
    setState(state);
    setZipCode(zip_code);
    setPhoneNumber(phone_number);
    setAltPhoneNumber(alt_phone_number);
    setShippingDate(available_ship_date); // Ensure this is correctly passed
    const newInputs =
      order_list?.map((item) => ({
        product_id: item.items.id,
        quantity: item.quantity,
        note: item.note,
      })) || [];

    setInputs(newInputs);
    setNoteVisible(new Array(newInputs.length).fill(false)); // Reset visibility states
  };

  const confirmDelete = () => {
    if (deleteOrderNumber) {
      dispatch(fetchDeleteOrder(deleteOrderNumber)).then(() => {
        setShouldReload((prev) => !prev);
      });
      setShowDeleteModal(false);
      setDeleteOrderNumber(null);
    }
  };

  const handleDeleteClick = (order_number) => {
    setDeleteOrderNumber(order_number);
    setShowDeleteModal(true);
  };
  const handleInputChange = (index, field, value) => {
    const newInputs = inputs?.map((input, i) =>
      i === index ? { ...input, [field]: value } : input
    );
    setInputs(newInputs);
  };

  // Function to add more input fields
  const handleAddMore = () => {
    setInputs((prevInputs) => [
      ...prevInputs,
      { product_id: null, quantity: 0, note: null }, // Add a new input field
    ]);
    setNoteVisible((prevNoteVisible) => [
      ...prevNoteVisible,
      false, // Add 'false' for the new input's note visibility
    ]);
  };
  const availableDateSearch = (e) => {
    e.preventDefault();
    dispatch(fetchOrderAvailableDateList(inputs));
  };

  const errorConditions = [
    { isError: isError, message: error },
    { isError: orderIsError, message: orderError },
    { isError: updateIsError, message: updateError },
    { isError: productListIsError, message: productListError },
  ];
  const cancel = (e) => {
    e.preventDefault();
    setCreateSector(false);
    setEditMode(false);
    setNoteVisible([false]);
    reset();
  };
  const toggleNoteVisibility = (index) => {
    setNoteVisible((prevVisible) =>
      prevVisible.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    if (success || updateSuccess || shouldReload) {
      reset();
      setCreateSector(false);
      setEditMode(false);
      setShouldReload(false);
    }
    dispatch(fetchOrderList());
    dispatch(fetchProductList);
  }, [
    navigate,
    userInfo,
    dispatch,
    success,
    reset,
    updateSuccess,
    shouldReload,
  ]);
  return (
    <Container>
      {(loading || updateLoading || productListLoading || orderLoading) && (
        <Loading />
      )}
      {errorConditions?.map(
        (condition, index) =>
          condition.isError && <Error key={index} message={condition.message} />
      )}
      <Row className="justify-content-md-center">
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h2>Manage Order </h2>
        </Col>
        {!createSector && !editMode && (
          <Col
            xs={6}
            md={6}
            sm={12}
            style={{ textAlign: "center", margin: "20px 0px" }}
          >
            <Button variant="info" onClick={createPage}>
              Create Order
            </Button>
          </Col>
        )}
      </Row>
      {!createSector && !editMode && (
        <Row className="justify-content-md-center">
          <Col
            xs={12}
            md={12}
            style={{ marginBottom: "20px", textAlign: "center" }}
          >
            <Form style={{ display: "flex" }} onSubmit={searchButton}>
              <Form.Group controlId="search-box" className="mx-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Order Number"
                  style={{ width: "400px" }}
                  value={searchNumber}
                  onChange={(e) => setSearchNumber(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" variant="outline-warning">
                Search
              </Button>
            </Form>
          </Col>
        </Row>
      )}

      {!createSector && !editMode && (
        <Table striped bordered hover>
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Order Number</th>
              <th>Customer Name</th>
              <th>Phone Number</th>
              <th>Product</th>
              <th>Shipping Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {Array.isArray(orders) &&
              orders?.map((order) => (
                <tr key={order.id}>
                  <td>{order?.order_number}</td>
                  <td>{order?.customer_name}</td>
                  <td>{order?.phone_number}</td>
                  <td>{order?.total_product || 0}</td>
                  <td>{order?.available_ship_date?.date}</td>
                  <td
                    style={{
                      color: order?.order_type === "CANCEL" ? "red" : "#1E9BCF",
                    }}
                  >
                    {order?.order_type}
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={editButton}
                      alt="edit"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        editPage(
                          order?.order_list,
                          order?.order_number,
                          order?.customer_name,
                          order?.shipping_address,
                          order?.city,
                          order?.state,
                          order?.zip_code,
                          order?.phone_number,
                          order?.alt_phone_number,
                          order?.available_ship_date?.id
                        )
                      }
                    />
                    <Image
                      src={viewButton}
                      alt="view"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => OrderDetails(order?.order_number)}
                    />
                    <Image
                      src={deleteButton}
                      alt="delete"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteClick(order?.order_number)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {(createSector || editMode) && (
        <Row className="justify-content-md-center">
          <Col xs={10} md={10} sm={12} style={{ textAlign: "center" }}>
            <Form onSubmit={editMode ? updateOrder : createOrder}>
              <Form.Group
                controlId="order_number"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Order Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Order Number"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                  required
                  style={{ flex: "3" }} // Match the width with the select
                />
              </Form.Group>
              <Form.Group
                controlId="customer_name"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Customer Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Customer Name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                  style={{ flex: "3" }} // Match the width with the select
                />
              </Form.Group>
              {/* <Form.Group
                controlId="shipping_address"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Shipping Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Shipping Address"
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                  required
                  style={{ flex: "3" }} // Match the width with the select
                />
              </Form.Group> */}
              <Form.Group
                controlId="shipping_address"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Shipping Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Shipping Address"
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                  required
                  style={{ flex: "3" }}
                />
              </Form.Group>

              <Form.Group
                controlId="city"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  City
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  style={{ flex: "3" }}
                />
              </Form.Group>

              <Form.Group
                controlId="state"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  State
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                  style={{ flex: "3" }}
                />
              </Form.Group>

              <Form.Group
                controlId="zip_code"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Zip Code
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                  style={{ flex: "3" }}
                />
              </Form.Group>

              <Form.Group
                controlId="phone_number"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  style={{ flex: "3" }} // Match the width with the select
                />
              </Form.Group>
              <Form.Group
                controlId="alt_phone_number"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Alt Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Alt Phone Number"
                  value={altPhoneNumber}
                  onChange={(e) => setAltPhoneNumber(e.target.value)}
                  style={{ flex: "3" }} // Match the width with the select
                />
              </Form.Group>

              <Form.Group
                controlId="shipdate"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                  Available Shipping Date
                </Form.Label>

                <Form.Control
                  as="select"
                  value={shippingDate}
                  onChange={(e) => setShippingDate(e.target.value)}
                  {...(!editMode && { required: true })}
                  disabled={!Array.isArray(dates)}
                  style={{ flex: "3" }} // Set the width of the input
                >
                  <option value="">Please Select Shipping Date</option>
                  {Array.isArray(dates) &&
                    dates?.map((date, index) => (
                      <option key={index} value={date?.id}>
                        {date?.shipment_date}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>

              {inputs?.map((input, index) => (
                <div
                  key={index}
                  className="d-flex mb-3"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Form.Group
                    controlId={`product_id${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Form.Label style={{ flex: "1", marginRight: "10px" }}>
                      Product
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={input?.product_id}
                      required
                      onChange={(e) =>
                        handleInputChange(index, "product_id", e.target.value)
                      }
                    >
                      <option value="">Please Select Your Product</option>
                      {Array.isArray(products) &&
                        products?.map((product, index) => (
                          <option key={index} value={product?.id}>
                            {product?.product_name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId={`quantity${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Form.Label>Quantity </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Product Quantity"
                      required
                      value={input.quantity}
                      onChange={(e) =>
                        handleInputChange(index, "quantity", e.target.value)
                      }
                    />
                  </Form.Group>
                  {!noteVisible[index] && (
                    <Image
                      src={addImg}
                      alt="add_note"
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleNoteVisibility(index)} // Toggle note visibility for the specific index
                    />
                  )}
                  {noteVisible[index] && (
                    <Form.Group
                      controlId={`note${index}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Form.Label>Note </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Write The Note Here (Not Required)"
                        value={input.note}
                        onChange={(e) =>
                          handleInputChange(index, "note", e.target.value)
                        }
                      />
                    </Form.Group>
                  )}
                </div>
              ))}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="available-search" onClick={availableDateSearch}>
                  <Image
                    src={searchPic}
                    alt="search"
                    style={{
                      width: "30px",
                      height: "30px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                  />{" "}
                  Click For Available Search Date
                </div>
                <Button
                  variant="outline-secondary "
                  onClick={handleAddMore}
                  className="me-2 my-2"
                >
                  Add More
                </Button>
              </div>
              <br />
              <Button
                type="submit"
                variant={editMode ? "outline-success" : "outline-info"}
                style={{ width: "600px" }}
              >
                {editMode ? "Update" : "Create"} Order
              </Button>
              <Button
                onClick={cancel}
                variant="outline-danger"
                style={{ width: "600px", marginTop: "10px" }}
              >
                Cancel
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
}
